<template>
  <FlashMessage class='z-50'/>
  <Suspense>
    <router-view/>
  </Suspense>
</template>

<script lang="ts">
  import FlashMessage from '@/components/FlashMessage.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    // eslint-disable-next-line vue/match-component-file-name
    name: 'SimpleLayout',
    components: {
      FlashMessage,
    },
  })
</script>
