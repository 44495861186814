import { ref } from 'vue'
import Modal from '../components/Modal.vue'
import { QuestionMarkCircleIcon } from '@heroicons/vue/solid'

export const InputFieldHasHelpProps = {
    help: {
        type: String,
        default: null,
    },
}

export const InputFieldHasHelpComponents = {
    QuestionMarkCircleIcon,
    Modal,
}

export default function() {
    const helpVisible = ref(false)

    function setHelpModalVisibility(visible: boolean) {
        helpVisible.value = visible
    }

    return {
        helpVisible,
        setHelpModalVisibility,
    }
}
