import { defineStore } from 'pinia'
import { timestamp } from '@vueuse/core'
import { NestedObject } from '@/types/general/utilTypes'

export type Notification = {
    id: string,
    type: string,
    message: string,
    description?: string,
    timestamp?: number
}

interface State {
    errors: NestedObject | NestedObject[] | [],
    notifications: Array<Notification>
}
export const useNotificationStore = defineStore('notification', {
    state: (): State => {
        return {
            errors: [],
            notifications: [],
        }
    },
    getters: {
        getErrors: state => state.errors,
        getNotifications: state => state.notifications,
    },

    actions: {
        resetNotifications() {
            this.notifications = []
        },
        deleteNotification(id: string) {
            this.notifications = this.notifications.filter(notification => notification.id !== id)
        },
        addNotification(notification: Notification) {
            notification.timestamp = timestamp()
            this.notifications.push(notification)
        },
        resetErrors() {
            this.errors = []
        },
        setErrors(errors: NestedObject) {
            this.errors = errors
        },
    },
})
