import { defineStore } from 'pinia'
import { loadMenu } from '@/api/requests/menu/getMenu'
import { LoadMenuResponseData } from '@/types/api/menu/menuResponses'
import { FlattenedMenu } from '@/types/api/menu/menuTypes'

export const useMenuStore = defineStore('menu', {
    state: () => {
        return {
            menu: null as LoadMenuResponseData | null,
            flattenedMenu: [] as FlattenedMenu,
        }
    },
    getters: {
        getMenu: state => state.menu,
        getFlattenedMenu: state => state.flattenedMenu,
    },
    actions: {
        async loadMenu() {
            if(!this.menu) {
                const menuResult = await loadMenu()

                this.menu = menuResult.menu
                this.flattenedMenu = menuResult.flattened_menu
            }
        },
    },
})
