import { EyeIcon,
    UsersIcon,
    FolderIcon,
    UserIcon,
    CollectionIcon,
    CubeTransparentIcon,
    GlobeAltIcon,
    AnnotationIcon,
    OfficeBuildingIcon,
    DocumentIcon,
    CalendarIcon,
    MailOpenIcon,
    PaperClipIcon,
    TableIcon,
    CogIcon,
    LockClosedIcon,
    DotsVerticalIcon,
    ClipboardIcon,
    ChatIcon,
    DocumentTextIcon,
    DatabaseIcon,
    CubeIcon,
    ChartSquareBarIcon,
    MenuIcon,
    BellIcon,
    ViewListIcon,
    ChatAltIcon,
    AdjustmentsIcon,
    BeakerIcon,
    CreditCardIcon,
    CurrencyDollarIcon,
    SpeakerphoneIcon,
    UserGroupIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CheckCircleIcon,
    XCircleIcon,
    ArrowCircleRightIcon,
    RefreshIcon,
    PlusIcon,
    PencilIcon,
    MailIcon,
    InboxInIcon,
    ShareIcon,
    CodeIcon,
    CurrencyEuroIcon,
    DownloadIcon,
    UploadIcon,
    AtSymbolIcon,
    ShieldCheckIcon,
    ServerIcon,
    PuzzleIcon,
    LibraryIcon,
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon,
    HomeIcon,
    ShieldExclamationIcon,
    DocumentReportIcon,
    CashIcon,
    TicketIcon,
    DocumentDuplicateIcon,
    ArrowLeftIcon,
    TranslateIcon,
    DuplicateIcon,
    ExternalLinkIcon } from '@heroicons/vue/outline'

export const IconComponents = {
    DuplicateIcon,
    HomeIcon,
    EyeIcon,
    UsersIcon,
    AtSymbolIcon,
    ShieldCheckIcon,
    FolderIcon,
    UserIcon,
    CollectionIcon,
    CubeTransparentIcon,
    GlobeAltIcon,
    AnnotationIcon,
    OfficeBuildingIcon,
    DocumentIcon,
    CalendarIcon,
    MailOpenIcon,
    PaperClipIcon,
    TableIcon,
    CogIcon,
    LockClosedIcon,
    DotsVerticalIcon,
    ClipboardIcon,
    ChatIcon,
    DocumentTextIcon,
    DatabaseIcon,
    CubeIcon,
    ChartSquareBarIcon,
    MenuIcon,
    BellIcon,
    ViewListIcon,
    ChatAltIcon,
    AdjustmentsIcon,
    BeakerIcon,
    CreditCardIcon,
    CurrencyDollarIcon,
    SpeakerphoneIcon,
    UserGroupIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CheckCircleIcon,
    XCircleIcon,
    ArrowCircleRightIcon,
    RefreshIcon,
    PlusIcon,
    PencilIcon,
    MailIcon,
    InboxInIcon,
    PuzzleIcon,
    ShareIcon,
    CodeIcon,
    CurrencyEuroIcon,
    DownloadIcon,
    UploadIcon,
    ServerIcon,
    LibraryIcon,
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon,
    ShieldExclamationIcon,
    DocumentReportIcon,
    CashIcon,
    TicketIcon,
    DocumentDuplicateIcon,
    ArrowLeftIcon,
    TranslateIcon,
    ExternalLinkIcon,
}

export type IconKey = keyof typeof IconComponents;
