import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import 'virtual:vite-plugin-sentry/sentry-config'
import './assets/index.postcss'

const pinia = createPinia()
const head = createHead()
const app = createApp(App)

if(import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_SENTRY_DSN !== 'false') {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'localhost',
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['localhost', /^\//, import.meta.env.VITE_API_BASE_URL.replace(/^https?:\/\//, '')],
            }),
        ],
        debug: false,
        dist: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist,
        release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 0.2,
        autoSessionTracking: true,
        logErrors: true,
    })
}

app.use(pinia)
app.use(router)
app.use(head)

app.mount('#app')

export default pinia
