import axios from 'axios'
import { LogoutUserResponse } from '@/api/requests/authentication/authenticationResponses'

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL

export const logoutUser = async () => {
    return await axios.post(API_BASE_URL + '/api/admin/auth/logout', {
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
        },
    }) as LogoutUserResponse
}