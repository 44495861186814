import { defineStore } from 'pinia'
import { AxiosRequestConfig } from 'axios'
import { NestedObject } from '@/types/general/utilTypes'
import { SelectOption } from '@/types/general/FieldType'

interface AbortedRequest {
    method: string | undefined,
    url: string | undefined,
    data: NestedObject | undefined,
    params: NestedObject | undefined
    headers: AxiosRequestConfig | undefined,
}

interface State {
    optionsLoaded: Map<string, SelectOption[]>,
    moduleLoading: Array<string|number>,
    rolesLoading: Array<string>,
    dataLoading: Array<string>,
    apiLoading: boolean,
    apiRequiresVerification: boolean,
    verificationAction: string,
    verificationActionCallback: null | (() => Promise<void>),
    apiSmallLoading: boolean,
    abortController: AbortController | undefined,
    abortedRequest: AbortedRequest,
}
export const useAppStore = defineStore('app', {
    state: (): State => {
        return {
            optionsLoaded: new Map(),
            moduleLoading: [],
            rolesLoading: [],
            dataLoading: [],
            apiLoading: true,
            apiRequiresVerification: false,
            verificationAction: '',
            verificationActionCallback: null,
            apiSmallLoading: false,
            abortController: undefined,
            abortedRequest: {
                method: undefined,
                url: undefined,
                data: undefined,
                params: undefined,
                headers: undefined,
            },
        }
    },
    getters: {
        isModuleLoading: state => state.moduleLoading.length > 0,
        isRolesLoading: state => state.rolesLoading.length > 0,
        isDataLoading: state => state.dataLoading.length > 0,
        isApiLoading: state => state.apiLoading,
        getApiRequiresVerification: state => state.apiRequiresVerification,
        getVerificationAction: state => state.verificationAction,
        getAbortedRequest: state => state.abortedRequest,
        isAppLoading(): boolean { return (this.apiLoading && !this.apiSmallLoading) || this.isModuleLoading || this.isRolesLoading || this.isDataLoading },
        getFieldOptions: (state) => {
            return (fieldId: string) => state.optionsLoaded.has(fieldId) ? state.optionsLoaded.get(fieldId) : []
        },
    },
    actions: {
        addModuleLoading(module: string | number) {
            this.moduleLoading.push(module)
        },
        removeModuleLoading(module: string | number) {
            this.moduleLoading = this.moduleLoading.filter(m => m !== module)
        },
        addRolesLoading(role: string) {
            this.rolesLoading.push(role)
        },
        removeRolesLoading(role: string) {
            this.rolesLoading = this.rolesLoading.filter(r => r !== role)
        },
        addDataLoading(data: string) {
            this.dataLoading.push(data)
        },
        removeDataLoading(data: string) {
            this.dataLoading = this.dataLoading.filter(d => d !== data)
        },
    },
})
