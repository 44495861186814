import myZoneServiceProvider from '@/api/serviceProviders/my-zone'
import { CreateFilterResponse,
    CreateFilterResponseData, DeleteFilterResponse, GetFilterResponse, GetFilterResponseData,
    GetFiltersResponse, GetFiltersResponseData, UpdateFilterResponse, UpdateFilterResponseData } from '@/types/api/filter/filterReponses'
import { CreateFilterRequestData, UpdateFilterRequestData } from '@/types/api/filter/filterTypes'

/**
 * Create a new filter
 * @returns CreateFilterResponseData
 * @param moduleId
 * @param filterId
 */
export const activateFilter = async (moduleId: number|null, filterId: number|null): Promise <GetFiltersResponseData> => {
    const response = await myZoneServiceProvider.post('filters/' + moduleId + '/' + filterId)

    return response.data.data
}

/**
 * Create a new filter
 * @param data
 * @returns CreateFilterResponseData
 */
export const createFilter = async (data: CreateFilterRequestData): Promise <CreateFilterResponseData> => {
    const response = await myZoneServiceProvider.post('filters', data) as CreateFilterResponse

    return response.data.data
}

/**
 * Delete filter
 * @param filterId
 * @returns {message: string;}
 */
export const deleteFilter = async (filterId: number): Promise <{ message: string; }> => {
    const response = await myZoneServiceProvider.delete('filters/' + filterId) as DeleteFilterResponse

    return response.data
}
/**
 * Get all filters for module
 * @param moduleId
 * @returns GetFiltersResponseData
 */
export const getFilters = async (moduleId: number): Promise <GetFiltersResponseData> => {
    const response = await myZoneServiceProvider.get('filters/' + moduleId) as GetFiltersResponse

    return response.data.data
}

/**
 * Get filter by id
 * @param filterId
 * @returns GetFiltersResponseData
 */
export const getFilter = async (filterId: number): Promise <GetFilterResponseData> => {
    const response = await myZoneServiceProvider.get('filters/id/' + filterId) as GetFilterResponse

    return response.data.data
}

/**
 * Update filter
 * @param filterId
 * @param data
 * @returns UpdateFilterResponseData
 */
export const updateFilter = async (filterId: number, data: UpdateFilterRequestData): Promise <UpdateFilterResponseData> => {
    const response = await myZoneServiceProvider.put('filters/' + filterId, data) as UpdateFilterResponse

    return response.data.data
}
