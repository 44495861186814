import myZoneServiceProvider from '@/api/serviceProviders/my-zone'
import { LoadMenuResponse, LoadMenuResponseData } from '@/types/api/menu/menuResponses'

/**
 * Load menu
 * @returns LoadMenuResponseData
 */
export const loadMenu = async (): Promise <LoadMenuResponseData> => {
    const response = await myZoneServiceProvider.get('menu') as LoadMenuResponse

    return response.data.data
}