<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live='assertive'
    class='pointer-events-none fixed inset-0 flex items-end py-6 px-4 sm:items-start sm:pt-16 sm:pr-4'
    :class='{"force-notification-z": notifications.length}'
  >
    <div class='flex w-full flex-col items-center space-y-4 sm:items-end'>
      <transition
        enter-active-class='transform ease-out duration-300 transition'
        enter-from-class='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
        enter-to-class='translate-y-0 opacity-100 sm:translate-x-0'
        leave-active-class='transition ease-in duration-100'
        leave-from-class='opacity-100'
        leave-to-class='opacity-0'
        :class='{"inset-0 pb-4 drop-shadow-2xl": notifications.length}'
      >
        <div
          v-if='notifications.length'
          class='w-full max-w-sm overflow-hidden'
        >
          <div
            v-for='notification in notifications'
            :key='notification.id'
            class='pointer-events-auto mb-2 rounded-lg bg-gray-700 pr-2 pb-4 pl-4 shadow-lg ring-1 ring-black'
            @mouseover='hoveredId = notification.id'
          >
            <div
              class='flex w-full shrink-0 justify-end'
            >
              <button
                :class='{ "invisible": hoveredId !== notification.id }'
                type='button'
                class='inline-flex rounded-md pt-2 text-gray-200 hover:text-gray-400 focus:outline-none'
                @click='deleteNotification(notification.id)'
              >
                <span class='sr-only'>Close</span>
                <XIcon
                  class='h-3 w-3'
                  aria-hidden='true'
                />
              </button>
            </div>

            <div class='flex pr-2'>

              <div class='flex shrink-0 items-center'>
                <CheckCircleIcon
                  v-if='notification.type === "success"'
                  class='h-6 w-6 text-green-400'
                  aria-hidden='true'
                />
                <XCircleIcon
                  v-else
                  class='h-6 w-6 text-red-400'
                  aria-hidden='true'
                />
              </div>

              <div class='ml-3 flex w-full flex-col pt-0.5'>
                <div class='flex w-full flex-row justify-between'>
                  <span class='text-sm leading-5 text-white'>{{ notification.message }}</span>

                  <span class='text-xs text-gray-200'>
                    {{ getFromNowDate(notification.timestamp) }}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'
  import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/outline'
  import { XIcon } from '@heroicons/vue/solid'
  import { useNotificationStore } from '@/store/notification'
  import { storeToRefs } from 'pinia'
  import { DateTime } from 'luxon'

  export default defineComponent({
    name: 'FlashMessage',
    components: {
      CheckCircleIcon,
      XCircleIcon,
      XIcon,
    },
    setup() {
      const notificationStore = useNotificationStore()
      const { notifications } = storeToRefs(notificationStore)
      const hoveredId = ref<string>('')

      const deleteNotification = (id: string) => {
        notificationStore.deleteNotification(id)
      }

      const getFromNowDate = (timestamp: number) => {
        const dateTime = DateTime.fromMillis(timestamp)

        return dateTime.toRelative()
      }

      watch(() => notificationStore.notifications, (newNotifications) => {
        newNotifications.forEach((notification) => {
          setTimeout(() => {
            deleteNotification(notification.id)
          }, notification.type === 'success' ? 2000 : 5000)
        })
      }, {
        deep: true, 
      })

      return {
        hoveredId,
        notifications,
        getFromNowDate,
        deleteNotification,
      }
    },
  })
</script>

<style>
.force-notification-z {
  z-index: 100 !important;
}
</style>