import myZoneServiceProvider from '@/api/serviceProviders/my-zone'
import { LoadAvailableBrandsOptionsResponseData,  LoadAvailableBrandsOptionsResponse,
    LoadAvailableLocalesResponse, LoadAvailableLocalesResponseData } from '@/types/api/brand/brandResponses'
import { BrandOption } from '@/types/api/brand/brandTypes'
import { Locale } from '@/types/general/LocaleType'

/**
 * Load all available brands as select options list
 * @returns LoadAvailableBrandsOptionsResponseData
 */
export const loadAvailableBrandsOptions = async (): Promise<LoadAvailableBrandsOptionsResponseData> => {
    const response = await myZoneServiceProvider.get('brands/available') as LoadAvailableBrandsOptionsResponse

    return response.data.data.map((brand: BrandOption) => {
        return {
            key: brand.key,
            label: brand.label,
        }
    })
}

/**
 * Load all available locales
 * @returns LoadAvailableLocalesResponseData
 */
export const loadAvailableLocales = async (brandId: number|null): Promise<LoadAvailableLocalesResponseData> => {
    const response = await myZoneServiceProvider.get('translations/locales/' + brandId) as LoadAvailableLocalesResponse

    return response.data.data.map((locale: Locale) => {
        return {
            key: locale.value,
            label: locale.label,
            active: locale.active,
            value: locale.value,
            icon: locale.value,
        }
    })
}
