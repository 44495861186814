import myZoneServiceProvider from '@/api/serviceProviders/my-zone'
import { SearchResponse, SearchResponseData } from '@/types/api/globalsearch/searchResponses'
import { AxiosRequestConfig } from 'axios'

/**
 * Global search through modules
 * @returns SearchResponseData
 */
export const searchInModules = async (searchable: string, queryParams?: AxiosRequestConfig): Promise <SearchResponseData> => {
    const response = await myZoneServiceProvider.get('search/' + searchable, {
        params: queryParams,
    }) as SearchResponse

    return response.data.data
}
