import { defineStore } from 'pinia'
import router from '@/router'
import { loginUser, loginUserWithToken, confirmLoginToken, refreshAccessToken, askForNewPassword, resetPassword } from '@/api/requests/user/login'
import { useLocalStorage } from '@vueuse/core'
import { useAppStore } from '@/store/app'
import { useModuleStore } from '@/store/module'
import { useBrandStore } from '@/store/brand'
import { useFilterStore } from '@/store/filter'
import { useNotificationStore } from '@/store/notification'
import { useMenuStore } from '@/store/menu'
import { useUserStore } from '@/store/user'
import { LoginUserActionResponse,
    LoginUserDataResponse,
    LoginUserResponseData } from '@/api/requests/authentication/authenticationResponses'
/* eslint-disable camelcase */
interface State {
    tokens: {
        access: string | null;
        refresh: string | null;
    },
    expires_in: number | null;
    refreshing: boolean;
}
export const useAuthStore = defineStore('auth', {
    state: (): State => {
        return {
            tokens: {
                access: null,
                refresh: null,
            },
            expires_in: null,
            refreshing: false,
        }
    },
    getters: {
        /**
         * Checks for presence of access token in state
         */
        isAuthenticated: state => !!state.tokens.access,

        accessToken: state => state.tokens.access,
        refreshToken: state => state.tokens.refresh,
        isRefreshing: state => state.refreshing,
    },

    actions: {

        /**
         * Load tokens from local storage
         */
        loadTokens() {
            const accessToken =  useLocalStorage('access_token', null)
            const refreshToken =  useLocalStorage('refresh_token', null)
            const expires_in =  useLocalStorage('expires_in', null)

            this.tokens = {
                access: accessToken.value,
                refresh: refreshToken.value,
            }
            this.expires_in = expires_in.value
        },
        /**
         * Logins user and saves tokens to local storage
         * @param login Users email
         * @param password Users password
         */
        async login(login: string, password: string) {
            return new Promise((resolve, reject) => {
                loginUser(login, password).then((response) => {
                    if (isActionResponse(response.data)) {
                        localStorage.setItem('action_token', response.data.action_token)
                        useAppStore().apiRequiresVerification = true
                        useAppStore().verificationAction = response.data.action

                        useAppStore().abortedRequest.data = {
                            login: login,
                            password: password,
                        }
                    } else if(isDataResponse(response.data)){
                        this.tokens = {
                            access: response.data.data?.access_token ?? undefined,
                            refresh: response.data.data?.refresh_token ?? undefined,
                        }
                        this.expires_in = response.data.data?.expires_in ?? undefined
                        useLocalStorage('access_token', this.tokens.access)
                        useLocalStorage('refresh_token', this.tokens.refresh)
                        useLocalStorage('expires_in', this.expires_in)

                        router.push('/')
                    }

                    resolve(response)
                }).catch(error => {
                    if (!error.response) useNotificationStore().setErrors(error)
                    else if (error.response.data.errors) useNotificationStore().setErrors(error.response.data.errors)
                    else if (error.response.data.message) useNotificationStore().setErrors({
                        message: error.response.data.message,
                    })
                    else useNotificationStore().setErrors(error)

                    reject(error)
                })
            })
        },

        async loginWithToken(token: string) {
            return new Promise((resolve, reject) => {
                loginUserWithToken(token).then((response) => {
                    this.tokens = {
                        access: response.data.data?.access_token ?? undefined,
                        refresh: response.data.data?.refresh_token ?? undefined,
                    }
                    this.expires_in = response.data.data?.expires_in ?? undefined
                    useLocalStorage('access_token', this.tokens.access)
                    useLocalStorage('refresh_token', this.tokens.refresh)
                    useLocalStorage('expires_in', this.expires_in)

                    router.push('/')

                    resolve(response)
                }).catch(error => {
                    if (!error.response) useNotificationStore().setErrors(error)
                    else if (error.response.data.errors) useNotificationStore().setErrors(error.response.data.errors)
                    else if (error.response.data.message) useNotificationStore().setErrors({
                        message: error.response.data.message,
                    })
                    else useNotificationStore().setErrors(error)

                    reject(error)
                })
            })
        },

        async confirmLoginToken(token: string) {
            return new Promise((resolve, reject) => {
                confirmLoginToken(token).then(response => {
                    resolve(response)
                }).catch(error => {
                    if (!error.response) useNotificationStore().setErrors(error)
                    else if (error.response.data.errors) useNotificationStore().setErrors(error.response.data.errors)
                    else if (error.response.data.message) useNotificationStore().setErrors({
                        message: error.response.data.message,
                    })
                    else useNotificationStore().setErrors(error)

                    reject(error)
                })
            })
        },

        askForPassword(email: string) {
            return askForNewPassword(email)
        },

        setPassword(password: string, passwordConfirmation: string, token: string) {
            return resetPassword(password, passwordConfirmation, token)
        },

        async refreshAccessToken() {
            try {
                const response = await refreshAccessToken()

                this.tokens = {
                    access: response.data.data?.access_token ?? undefined,
                    refresh: response.data.data?.refresh_token ?? undefined,
                }

                localStorage.setItem('access_token', response.data.data?.access_token)
                localStorage.setItem('refresh_token', response.data.data?.refresh_token)

                this.refreshing = false
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch(error: any) {
                this.logout()

                return error.response.data
            }
        },
        logout() {
            this.tokens = {
                access: null,
                refresh: null,
            }
            this.expires_in = null
            const accessToken = useLocalStorage('access_token', null)
            const refreshToken = useLocalStorage('refresh_token', null)
            // eslint-disable-next-line camelcase
            const expires_in = useLocalStorage('expires_in', null)

            accessToken.value = null
            refreshToken.value = null
            // eslint-disable-next-line camelcase
            expires_in.value = null

            useAppStore().$reset()
            useUserStore().$reset()
            useAuthStore().$reset()
            useBrandStore().$reset()
            useFilterStore().$reset()
            useNotificationStore().$reset()
            useModuleStore().$reset()
            useMenuStore().$reset()

            if (!['login', 'forgotten-password', 'reset-password'].includes(router.currentRoute.value.name))
                router.push('/login')
        },
    },
})

// Type guard for LoginUserActionResponse
function isActionResponse(data: LoginUserResponseData): data is LoginUserActionResponse {
    return (data as LoginUserActionResponse)?.action_token !== undefined
}

// Type guard for LoginUserDataResponse
function isDataResponse(data: LoginUserResponseData): data is LoginUserDataResponse {
    return (data as LoginUserDataResponse).data?.access_token !== undefined
}
