import axios, { AxiosRequestConfig } from 'axios'
import { useAuthStore } from '@/store/authentication'
import { useNotificationStore } from '@/store/notification'
import { useAppStore } from '@/store/app'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import router from '@/router'
import { useModuleStore } from '@/store/module'
import { useUserStore } from '@/store/user'

const BASE_URL = import.meta.env.VITE_API_BASE_URL + '/api/admin/'

const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
})

function refreshAuthLogic(error) {
    const authStore = useAuthStore()

    if (localStorage.getItem('access_token') === null)
        return authStore.logout()

    if (
        error?.response?.config?.url?.indexOf('login') !== -1 ||
        error?.response?.config?.url?.indexOf('logout') !== -1
    ) {
        return Promise.reject(error)
    }

    if(authStore.isRefreshing) {
        return Promise.resolve()
    }
    authStore.refreshing = true

    return authStore.refreshAccessToken().then(() => {
        error.response.config.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token')

        return Promise.resolve()
    }).catch(() => {
        authStore.logout()
    })
}

api.interceptors.request.use((config: AxiosRequestConfig) => {
    const appStore = useAppStore()

    const withAppLoader = config.data?.withAppLoader ?? true

    if (appStore && withAppLoader) appStore.apiLoading = true

    if (!config.url?.includes('users/verify-code')) {
        appStore.abortedRequest.url = config.url
        appStore.abortedRequest.method = config.method?.toString()
        appStore.abortedRequest.data = config.data
        appStore.abortedRequest.headers = config.headers

        if (appStore.apiRequiresVerification) {
            appStore.apiLoading = false
            appStore.abortController?.abort()

            return Promise.reject(new Error('Verification required'))
        }
    }

    config.signal = appStore?.abortController?.signal

    config.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token')

    if (localStorage.getItem('action_token')) {
        config.headers.common['X-Action-Token'] = localStorage.getItem('action_token')
        // localStorage.removeItem('action_token')      //do not remove item, login gets messy after incorrect code
    }

    return config
})

api.interceptors.response.use(response => {
    const notificationStore = useNotificationStore()

    if (response.data.requires_verification === true) {
        useAuthStore().refreshing = false
        useAppStore().apiRequiresVerification = true
        useAppStore().verificationAction = response.data.action
    } else if (response.data.is_verified === true) {
        useAppStore().apiRequiresVerification = false
    }

    if (response.data.qr_base64 && response.data.secret) {
        useUserStore().googleQr = response.data.qr_base64
        useUserStore().googleSecret = response.data.secret
    }

    if (response.status === 200) {
        useAuthStore().refreshing = false
    }

    if (response.data.message) {
        notificationStore.addNotification({
            id: Math.random().toString(36).slice(2, 7),
            type: 'success',
            message: response.data.message,
        })
    }

    if (response.data.redirect_url) {
        if (router.currentRoute.value.path !== response.data.redirect_url)
            router.push(response.data.redirect_url)
        else
            window.location.reload()
    }

    useAppStore().apiLoading = false

    return response
}, (error) => {
    if (error.message === 'canceled') {
        // useAppStore().dataLoading = []
        return Promise.resolve()
    }
    const notificationStore = useNotificationStore()

    if (error.response && error.response.status === 404) {
        useAppStore().apiLoading = false
        router.go(-1)
        notificationStore.setErrors(error.response.data.errors)
    }

    if (error.response && error.response.status === 422) {
        useAppStore().apiLoading = false
        useModuleStore().editEnabled = true
        notificationStore.setErrors(error.response.data.errors)
    }

    if (error.response && error.response.data.message && error.response.data.message !== 'Unauthenticated.') {
        notificationStore.addNotification({
            id: Math.random().toString(36).slice(2, 7),
            type: 'error',
            message: error.response.data.message,
        })
    }

    return Promise.reject(error)
})

createAuthRefreshInterceptor(api, refreshAuthLogic)

export default api
