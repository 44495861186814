import myZoneServiceProvider from '@/api/serviceProviders/my-zone'
import { GetLabelsResponse,
    GetOptionsResponse,
    GetOptionsResponseData } from '@/types/api/field/fieldResponses'
import { GetSelectOptionsParams } from '@/types/api/field/SelectTypes'

/**
 * Get options for select field
 * @param fieldId
 * @param params
 * @returns GetOptionsResponseData
 */
export const getOptions = async (fieldId: number, params: GetSelectOptionsParams): Promise <GetOptionsResponseData> => {
    const response = await myZoneServiceProvider.post('fields/' + fieldId + '/options', params) as GetOptionsResponse

    return response.data.data
}

/**
 * Get labels for select field
 * @param fieldId
 * @param recordIds
 * @returns GetLabelsResponseData
 */
export const getLabels = async (fieldId: number|string, recordIds: number[]): Promise <GetLabelsResponse> => {
    return await myZoneServiceProvider.post('fields/' + fieldId + '/labels', {
        recordIds,
    }) as GetLabelsResponse
}
