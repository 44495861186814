import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useModuleStore } from '@/store/module'
import { useAppStore } from '@/store/app'
import authRoutes from './modules/auth'
import { generateModuleRoutes } from '@/router/helpers/generateModuleRoutes'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/pages/DashboardPage.vue'),
        meta: {
            layout: 'DefaultLayout',
            module: null,
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/pages/ProfileSettings.vue'),
        meta: {
            layout: 'DefaultLayout',
            module: null,
        },
    },
    ...authRoutes,
    ...generateModuleRoutes,
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/pages/SettingsPage.vue'),
        meta: {
            layout: 'DefaultLayout',
            module: 'settings',
        },
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from) => {
    // Necessary to set route meta.module so route can be recognized
    to.meta.module = to.params.module

    // check if parameter exists and if its empty, set to default
    if (typeof to.params.tab === 'string' && to.params.tab.length === 0) to.params.tab = 'detail'

    if (to.meta.module !== from.meta.module) {
        if (from.meta.module && to.name !== 'profile') {
            useAppStore().abortController?.abort()
        }
    }
})

router.afterEach((to, from) => {
    const moduleStore = useModuleStore()

    if (to.meta.view !== from.meta.view || to.name !== from.name) useAppStore().optionsLoaded = new Map()

    const module = to.meta.module as string

    if(module) {
        if (! moduleStore.module || moduleStore.module.name !== module) {
            moduleStore.describeModule(module)
            moduleStore.editEnabled = false
        }
    }
})

export default router
