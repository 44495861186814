import axios from 'axios'
import myZoneServiceProvider from '@/api/serviceProviders/my-zone'
import { useUserStore } from '@/store/user'
import { AskForNewPasswordResponse,
    GetUserResponse,
    GetVerificationChannelsResponse,
    LoginUserResponse,
    RefreshAccessTokenResponse, RegisterGoogleChannelResponse,
    ResetPasswordResponse,
    SetNewPasswordResponse,
    SetVerificationChannelResponse } from '@/api/requests/authentication/authenticationResponses'
import { ExtendedApiResponse } from '@/types/general/ResponseType'

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL

/**
 * Login to MyZone
 *
 * @param email Users email
 * @param password Users password
 */
export const loginUser = async (email: string, password: string) => {
    return await axios.post(API_BASE_URL + '/api/admin/auth/login', {
        email: email,
        password: password,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }) as LoginUserResponse
}

export const loginWithIToken = async (token: string) => {
    return await axios.post(API_BASE_URL + '/api/admin/auth/login/itoken', {
        token: token, 
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }) as LoginUserResponse
}

export const loginUserWithToken = async (token: string) => {
    return await axios.post(API_BASE_URL + '/api/admin/auth/login/token', {
        token: token,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }) as LoginUserResponse
}

export const confirmLoginToken = async (token: string) => {
    return await axios.post(API_BASE_URL + '/api/admin/auth/login/confirm-token', {
        token: token,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }) as ExtendedApiResponse<void>
}

/**
 * Store info about logged user
 *
 */
export const getUserInfo = async () => {
    return await myZoneServiceProvider.get(API_BASE_URL + '/api/admin/auth/user').then((response: GetUserResponse) => {
        if (response?.data?.data) {
            useUserStore().user = response.data.data
        }

        return useUserStore().user
    }) as GetUserResponse
}

export const askForNewPassword = async (email: string) => {
    return await axios.post(API_BASE_URL + '/api/admin/auth/reset-password', {
        email: email,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }) as AskForNewPasswordResponse
}

export const registerGoogleChannel = async (action: string) => {
    return await myZoneServiceProvider.post('users/google-auth/register', {
        action_name: action,
    }) as RegisterGoogleChannelResponse
}

export const setVerificationChannel = async (channel: string, action: string) => {
    return await myZoneServiceProvider.post('users/verification-channel', {
        verification_channel: channel,
        action_name: action,
    }) as SetVerificationChannelResponse
}

export const set2faMethod = async (method: string, enable: boolean, code?: string) => {
    return await myZoneServiceProvider.post('users/auth/2fa/' + method, {
        enable: enable ? '1' : '0',
        code: code || '',
    }) as GetUserResponse
}

export const getVerificationChannels = async (action: string|undefined) => {
    return await myZoneServiceProvider.get('/channels' + (action ? '/' + action : '')) as GetVerificationChannelsResponse
}

export const resetPassword = async (password: string, passwordConfirmation: string, token: string) => {
    return await axios.post(API_BASE_URL + '/api/admin/auth/reset-password/' + token, {
        password: password,
        password_confirmation: passwordConfirmation,
        token: token,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }) as ResetPasswordResponse
}

export const setNewPassword = async (id: number, password: string, confirmation: string) => {
    return await myZoneServiceProvider.post(API_BASE_URL + '/api/admin/users/'+ id +'/set-password', {
        password: password,
        password_confirmation: confirmation,
    }) as SetNewPasswordResponse
}

export const refreshAccessToken = async (refreshToken = localStorage.getItem('refresh_token')) => {
    return await axios.post(API_BASE_URL + '/api/admin/auth/login/refresh', {
        refresh_token: refreshToken,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }) as RefreshAccessTokenResponse
}
