<template>
  <div v-bind='$attrs'>
    <label
      v-if='label'
      :for='name'
      class='block gap-0.5 truncate text-sm font-bold'
      :class='[(disabled && editMode) ? "text-gray-400" : "text-gray-700"]'
    >
      <QuestionMarkCircleIcon
        v-if='help'
        class='question-mark-circle-icon inline-block h-4 w-4 cursor-pointer'
        @click='setHelpModalVisibility(true)'
      />
      <span
        :title='field?.label'
        v-html='getLabel()'
      />
    </label>
    <template v-if='editMode'>
      <div class='group relative mt-1'>
        <div
          v-if='withIcon'
          class='pointer-events-none absolute inset-y-0 left-3 flex items-center py-3'
        >
          <component
            :is='icon'
            class='shrink-0'
            :class='[ isFilterField ? "h-4 w-4" : "h-6 w-6" ]'
            aria-hidden='true'
          />
        </div>
        <input
          :id='name'
          data-cy='string-field-input'
          :value='fieldValue'
          :name='name'
          :type='type'
          :placeholder='placeholder'
          :aria-describedby='ariaDescribedBy'
          :disabled='disabled'
          class='relative z-0 block w-full rounded-md shadow-sm sm:text-sm'
          :class='[
            error ? "border-red-300 focus:ring-red-500 focus:border-red-500 caret-red-500 selection:bg-red-300 selection:text-red-900" : "border-gray-300 focus:ring-gray-500 focus:border-gray-500",
            disabled ? "bg-gray-50 text-gray-400" : "",
            withIcon ? "pl-11" : "",
            isFilterField ? "pr-11" : "",
            inputClasses
          ]'
          @input='updateValue($event.target.value)'
          @focus='$emit("has-focus", name)'
        >
        <div
          v-if='isFilterField'
          class='absolute inset-y-0 right-3 z-10 flex h-0 w-0 cursor-pointer items-center opacity-0 transition-opacity group-hover:h-auto group-hover:w-auto group-hover:opacity-100'
          title='Filter empty values'
          @click='filterEmptyValues'
        >
          <FilterIcon
            :is='icon'
            class='h-4 w-4 shrink-0 opacity-0 group-hover:opacity-100'
            aria-hidden='true'
          />
        </div>
      </div>
      <InputError :error='error'/>
    </template>
    <template v-else>
      <div class='inline-flex items-center gap-2 truncate text-sm'>
        <span
          data-cy='preview-value'
          :title='modelValue'
        >
          {{ fieldValue }}
        </span>
        <slot name='fieldValue' />
        <span
          v-if='field.type === "color"'
          class='ml-4 rounded-full px-4 text-xs shadow-sm'
          :style='{ background: fieldValue }'
        >
          &nbsp;
        </span>
      </div>

    </template>
  </div>
  <Modal
    :open='helpVisible'
    :title='label'
    @close-modal='setHelpModalVisibility(false)'
  >
    <template #content>
      <p v-html='help'/>
    </template>
  </Modal>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'
  import useInputFieldBase, { InputFieldProps, InputFieldComponents, InputFieldEmits } from '../../../compositions/useInputFieldBase'
  import useInputFieldHasHelp, { InputFieldHasHelpProps, InputFieldHasHelpComponents } from '../../../compositions/useInputFieldHasHelp'
  import fromExponential from 'from-exponential'
  import { FilterIcon } from '@heroicons/vue/outline'

  export default defineComponent({
    name: 'StringField',
    components: {
      ...InputFieldComponents,
      ...InputFieldHasHelpComponents,
      FilterIcon,
    },
    props: {
      ...InputFieldProps,
      ...InputFieldHasHelpProps,
      type: {
        type: String,
        default: 'text',
      },
      withIcon: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: null,
      },
    },
    emits: [
      ...InputFieldEmits,
      'has-focus',
    ],
    setup(props) {
      const { helpVisible, setHelpModalVisibility } = useInputFieldHasHelp()
      const { updateValue, getLabel } = useInputFieldBase()

      const fieldValue = computed(() => {
        if (props.modelValue === '__EMPTY__') return 'Empty'

        // handle money type
        if (props.field.type === 'money' || props.field.type === 'quantity' && !props.isFilterField) {
          if (typeof (fromExponential(parseFloat(parseFloat(props.modelValue).toFixed(8)))) === 'string') return props.modelValue

          let symbol = ''
          const parts: string[] = typeof (props.modelValue) === 'string' ? props.modelValue.split(' ') : props.modelValue

          if (parts.length > 1) symbol = parts[1]
          if (props.modelValue === 0) return '0'

          // Check if props.modelValue is still string after parser

          return props.modelValue ? fromExponential(parseFloat(parseFloat(props.modelValue).toFixed(8))) + ' ' + symbol : ''
        } else return props.modelValue
      })

      const filterEmptyValues = () => {
        if (props.modelValue === '__EMPTY__') updateValue('')
        else updateValue('__EMPTY__')
      }

      return {
        updateValue, getLabel, helpVisible, setHelpModalVisibility, fieldValue, filterEmptyValues,
      }
    },
  })
</script>
