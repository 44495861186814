<template>
  <div
    v-if='appStore.isAppLoading'
    class='fixed top-16 left-0 z-20 h-[calc(100vh-32px)] w-full'
  >
    <div class='absolute -top-3 left-0 z-[999] h-full w-full bg-gray-700/20 backdrop-blur-sm backdrop-grayscale'/>
    <LoaderSpinner/>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import LoaderSpinner from '@/components/LoaderSpinner.vue'
  import { useAppStore } from '@/store/app'

  export default defineComponent({
    name: 'AppLoader',
    components: {
      LoaderSpinner,
    },
    setup() {
      const appStore = useAppStore()

      return {
        appStore,
      }
    },
  })
</script>
