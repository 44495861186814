import { defineStore } from 'pinia'
import { loadAvailableBrandsOptions, loadAvailableLocales } from '@/api/requests/brand/brandRequests'
import { Locale } from '@/types/general/LocaleType'
import { BrandOption } from '@/types/api/brand/brandTypes'

export const useBrandStore = defineStore('brand', {
    state: () => {
        return {
            brands: [] as Array<BrandOption>,
            availableLocalesForBrand: [{
                id: 'en',
                icon: 'en',
                label: 'English',
                value: 'en',
                active: true,
            }] as Array<Locale>,
        }
    },
    getters: {
        allBrands: (state) => state.brands,
        availableLocales: (state) => state.availableLocalesForBrand as Locale[],
    },

    actions: {
        async loadAvailableBrands() {
            this.brands = await loadAvailableBrandsOptions()
        },
        async loadAvailableLocales(brandId: number|null){
            this.availableLocalesForBrand = await loadAvailableLocales(brandId)
        },
    },
})
