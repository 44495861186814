<template>
  <div class='relative mb-4'>
    <div
      class='absolute inset-0 flex items-center'
      aria-hidden='true'
    >
      <div
        class='w-full border-t'
        :class='[dividerColor]'
      />
    </div>
    <div class='relative flex justify-start'>
      <span
        class='max-w-xs truncate pr-3 text-sm uppercase'
        :title='name'
        :class='[textColor, bgColor]'
      > {{ name }} </span>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'DividerComponent',
    props: {
      name: {
        type: String,
        default: '',
      },
      dividerColor: {
        type: String,
        default: 'border-gray-300',
      },
      textColor: {
        type: String,
        default: 'text-gray-500',
      },
      bgColor: {
        type: String,
        default: 'bg-white',
      },
    },
  })
</script>
