<template>
  <TransitionRoot
    :show='moreItems.length > 0'
    as='div'
    appear
    class='absolute left-full z-30 hidden h-screen w-28 overflow-y-auto border-l border-gray-600 bg-gray-700 px-2 drop-shadow-lg md:block'
    enter='transition-opacity duration-500'
    enter-from='opacity-0'
    enter-to='opacity-100'
    leave='transition-opacity duration-500'
    leave-from='opacity-100'
    leave-to='opacity-0'
  >
    <div
      v-for='(groups, index) in moreItems'
      :key='groups.label'
      :class='[ moreItems.length - 1 !== index && "border-b border-gray-500" ]'
    >
      <TransitionChild
        appear
        enter='transition ease-in-out duration-300 transform'
        enter-from='-translate-x-full'
        enter-to='translate-x-0'
        leave='transition ease-in-out duration-300 transform'
        leave-from='translate-x-0'
        leave-to='-translate-x-full'
      >
        <RouterLink
          v-for='item in groups'
          :key='item.name'
          v-slot='{ href, route, navigate }'
          :to='"/" + item.name'
          custom
        >
          <div
            class='group relative my-1 flex w-full flex-col items-center justify-center rounded-md text-xs font-medium hover:bg-gray-500'
          >
            <a
              :class='[isActiveRoute(route) ? "bg-gray-800 text-white" : "text-gray-100 hover:text-white"]'
              class='relative z-10 flex w-full cursor-pointer flex-col items-center justify-center rounded-md p-3'
              :aria-current='isActiveRoute(route) ? "page" : undefined'
              @click.exact='navigateTo(navigate)'
              @click.meta.exact='openInNewTab(href)'
              @click.ctrl.exact='openInNewTab(href)'
              @mouseleave='showLess'
            >
              <component
                :is='
                  item.icon'
                :class='[isActiveRoute(route) ? "text-white" : "text-gray-300 group-hover:text-white", "h-6 w-6"]'
                aria-hidden='true'
              />
              <span class='mt-2 text-center'>{{ item.label }}</span>
            </a>
            <div
              v-if='item.public_filters'
              class='absolute right-0 z-20 cursor-pointer text-white opacity-0 transition-all group-hover:opacity-100 group-hover:duration-500'
              @click='showFilterMenu({ id: item.id, href: href})'
            >
              <ChevronRightIcon
                class='h-5 w-5'
                aria-hidden='true'
              />
            </div>
          </div>
        </RouterLink>
      </TransitionChild>
    </div>
  </TransitionRoot>
</template>

<script lang='ts'>
  import { defineComponent, ref, watch } from 'vue'
  import { useRoute, RouterLink, NavigationFailure, RouteLocation } from 'vue-router'
  import { IconComponents } from '@/compositions/useIconComponents'
  import { ChevronRightIcon } from '@heroicons/vue/solid'
  import { TransitionRoot, TransitionChild } from '@headlessui/vue'

  export default defineComponent({
    name: 'ExtendedNarrowSidebar',
    components: {
      ...IconComponents,
      ChevronRightIcon,
      RouterLink,
      TransitionRoot,
      TransitionChild,
    },
    props: {
      items: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type: Object,
        default: null,
      },
    },
    emits: ['show-filter-menu', 'hide-filter-menu', 'hide-extended-sidebar'],
    setup(props, { emit }) {
      const route = useRoute()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const moreItems = ref<Array<any>>([])

      const isActiveRoute = (menuRoute: RouteLocation) => {
        return route.meta.module === menuRoute.params.module
      }

      const showFilterMenu = (module: object) => emit('show-filter-menu', module)
      const showLess = () => emit('hide-filter-menu')

      const hideExtendedSidebar = () => emit('hide-extended-sidebar')

      const navigateTo = (navigate: (e?: MouseEvent) => Promise<void | NavigationFailure>) => {
        navigate()
        hideExtendedSidebar()
      }

      const openInNewTab = (href: string) => {
        window.open(href, '_blank')
      }

      watch(() => props.items, (newValue) => {
        moreItems.value = newValue
      })

      return {
        moreItems,
        isActiveRoute,
        showFilterMenu,
        showLess,
        hideExtendedSidebar,
        navigateTo,
        openInNewTab,
      }
    },
  })
</script>
