<template>
  <div
    v-if='error'
    data-cy='input-error'
  >
    <p
      v-for='e in error'
      :id='e'
      :key='e'
      class='mt-1 text-xs text-red-600'
      data-cy='input-error-message'
    >
      <ExclamationIcon class='inline-block h-3.5 w-3.5'/>
      {{ e }}
    </p>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ExclamationIcon } from '@heroicons/vue/solid'

  export default defineComponent({
    name: 'InputError',
    components: {
      ExclamationIcon,
    },
    props: {
      error: {
        type: Array,
        default: () => [],
      },
    },
  })
</script>
