<template>
  <TransitionRoot
    as='template'
    :show='show'
  >
    <Dialog
      as='div'
      class='fixed inset-0 z-50 overflow-y-auto'
      @close='$emit("close-modal")'
    >
      <div class='flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:p-0'>
        <TransitionChild
          as='template'
          enter='ease-out duration-300'
          enter-from='opacity-0'
          enter-to='opacity-100'
          leave='ease-in duration-200'
          leave-from='opacity-100'
          leave-to='opacity-0'
        >
          <DialogOverlay class='fixed inset-0 -z-10 bg-gray-500/75 transition-opacity' />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class='hidden sm:inline-block sm:h-screen sm:align-middle'
          aria-hidden='true'
        >&#8203;</span>
        <TransitionChild
          as='template'
          enter='ease-out duration-300'
          enter-from='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          enter-to='opacity-100 translate-y-0 sm:scale-100'
          leave='ease-in duration-200'
          leave-from='opacity-100 translate-y-0 sm:scale-100'
          leave-to='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
        >
          <div
            class='relative inline-block overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:align-middle'
            :class='[modalClasses]'
          >
            <div class='absolute top-0 right-0 hidden pt-4 pr-4 sm:block'>
              <button
                type='button'
                class='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'
                @click='$emit("close-modal")'
              >
                <span class='sr-only'>Close</span>
                <XIcon
                  class='h-6 w-6'
                  aria-hidden='true'
                />
              </button>
            </div>
            <div class='flex h-full flex-col'>
              <DialogTitle
                v-if='title'
                as='h3'
                class='text-lg font-medium leading-6 text-gray-900'
                :class='[titleClasses]'
              >
                {{ title }}
              </DialogTitle>
              <div :class='[contentClasses]'>
                <slot name='content'/>
              </div>
            </div>
            <slot name='buttons'/>
            <!--<div class="mt-5 sm:mt-6">
              <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm" @click="$emit('closeModal')">
                Go back to dashboard
              </button>
            </div>-->
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'
  import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { XIcon } from '@heroicons/vue/outline'

  export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Modal',
    components: {
      Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionChild,
      TransitionRoot,
      XIcon,
    },
    props: {
      title: {
        type: String,
        default: null,
      },
      content: {
        type: String,
        default: null,
      },
      open: {
        type: Boolean,
        default: false,
      },
      modalClasses: {
        type: String,
        default: 'sm:max-w-sm sm:w-full sm:p-6 px-4 pt-5 pb-4',
      },
      titleClasses: {
        type: String,
        default: 'text-center mb-4',
      },
      contentClasses: {
        type: String,
        default: '',
      },
    },
    emits: ['close-modal'],
    setup(props) {
      const show = ref(props.open)

      watch(() => props.open, (newValue) => {
        show.value = newValue
      })

      return {
        show,
      }
    },
  })
</script>
