<template>
  <div>
    <Modal
      :open='isOpen'
      title='Send verification code'
      modal-classes='w-1/3 h-1/2 p-0 absolute'
      title-classes='text-left border-b px-8 pt-5 pb-4'
      content-classes='px-4 pt-5 bg-gray-50 h-screen overflow-y-auto pb-20'
      @close-modal='closeModal'
    >
      <template #content>
        <div class='mx-auto my-4 grid w-full max-w-sm'>
          <StringField
            v-model='code'
            name='otp'
            label='Code'
            :field='{ mandatory: true }'
            :edit-mode='true'
            :error='hasError ? ["Incorrect code"] : null'
          />
        </div>
        <div class='mx-auto w-full max-w-sm'>
          <div class='mt-5 inline-block w-full'>
            <div class='flex flex-row justify-between'>
              <button
                type='button'
                class='my-3 items-center rounded-md border border-gray-300 bg-gray-100 py-1.5 px-5 text-sm text-gray-500 hover:bg-gray-100 focus:ring-0'
                @click='resendCode'
              >
                <span v-if='!resending'>Resend code</span>
                <ProcessingButton v-else />
              </button>

              <div class='flex flex-row'>
                <button
                  type='button'
                  class='my-3 items-center rounded-md py-1.5 px-5 text-sm text-white focus:ring-0'
                  :class='[sending || !canSubmit ? "bg-gray-600/50 hover:bg-gray-500/50 cursor-not-allowed" : "bg-gray-600 hover:bg-gray-500"]'
                  :disabled='sending || !canSubmit'
                  @click='sendCode'
                >
                  <span v-if='!sending'>Send</span>
                  <ProcessingButton v-else />
                </button>
                <!--                <button-->
                <!--                  type='button'-->
                <!--                  class='my-3 items-center rounded-md border border-gray-300 bg-white py-1.5 px-5 text-sm text-gray-500 hover:bg-gray-100 focus:ring-0'-->
                <!--                  @click='closeModal'-->
                <!--                >Cancel</button>-->
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import Modal from '@/components/Modal.vue'
  import { IconComponents } from '@/compositions/useIconComponents'
  import ProcessingButton from '@/components/loaders/ProcessingButton.vue'
  import { useAppStore } from '@/store/app'
  import { useNotificationStore } from '@/store/notification'
  import StringField from '@/components/inputs/string-field/StringField.vue'
  import myZoneServiceProvider from '@/api/serviceProviders/my-zone'
  import router from '@/router'
  import { useAuthStore } from '@/store/authentication'
  import { useModuleStore } from '@/store/module'

  export default defineComponent({
    name: 'VerificationModal',
    components: {
      StringField,
      ProcessingButton,
      Modal,
      ...IconComponents,
    },
    setup() {
      const isOpen = ref(false)
      const code = ref(null)

      onMounted(() => {
        isOpen.value = useAppStore().apiRequiresVerification
      })

      watch(() => useAppStore().apiRequiresVerification, (newValue) => {
        isOpen.value = newValue
      })

      function closeModal() {
        isOpen.value = false
      }

      const withMissing = ref(false)

      const sending = ref(false)
      const resending = ref(false)
      const hasError = ref(false)

      const resendCode = async () => {
        resending.value = true

        return await myZoneServiceProvider.post('users/resend-code', {
          action_name: useAppStore().verificationAction,
        }).then(response => {
          if (response.data.code_sent === true) {
            useNotificationStore().addNotification({
              id: Math.random().toString(36).slice(2, 7),
              type: 'success',
              message: 'A new code has been sent!',
            })
          } else {
            hasError.value = true
          }

          sending.value = false
        })
      }

      const sendCode = async () => {
        const appStore = useAppStore()

        sending.value = true

        return await myZoneServiceProvider.post('users/verify-code', {
          code: code.value,
          action_name: useAppStore().verificationAction,
        }).then((response: object) => {
          if (response.data.is_verified === true) {
            code.value = null
            hasError.value = false
            appStore.apiRequiresVerification = false

            if (router.currentRoute.value.fullPath.includes('/login')) {
              if (appStore.verificationActionCallback) {
                appStore.verificationActionCallback()
                appStore.verificationActionCallback = null
                
                return
              }

              return useAuthStore().login(appStore.abortedRequest?.data?.login, appStore.abortedRequest?.data?.password)
            }

            return myZoneServiceProvider.request({
              method: appStore.abortedRequest.method as string,
              url: appStore.abortedRequest.url,
              headers: appStore.abortedRequest.headers,
              params: appStore.abortedRequest.params,
              data: appStore.abortedRequest.data,
            }).then(async () => {
              useModuleStore().refresh = true
              sending.value = false
              if (appStore.verificationActionCallback) {
                await appStore.verificationActionCallback()
                  .finally(() => {
                    appStore.verificationActionCallback = null
                  })
              }
            })
          } else {
            hasError.value = true
          }

          sending.value = false
        })
      }

      const canSubmit = computed(() => {
        return code.value
      })

      return {
        isOpen,
        closeModal,
        sending,
        resending,
        hasError,
        withMissing,
        canSubmit,
        sendCode,
        resendCode,
        code,
      }
    },
  })

</script>
