export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/LoginPage.vue'),
        meta: {
            layout: 'SimpleLayout',
            module: null,
        },
    },
    {
        path: '/forgotten-password',
        name: 'forgotten-password',
        component: () => import('@/pages/ForgottenPassword.vue'),
        meta: {
            layout: 'SimpleLayout',
            module: null,
        },
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => import('@/pages/ResetPassword.vue'),
        meta: {
            layout: 'SimpleLayout',
            module: null,
        },
    },
]
