<template>
  <div class='pointer-events-auto h-full w-screen max-w-xs text-white'>
    <div class='flex h-full flex-col overflow-y-scroll border-l border-l-gray-600 bg-gray-700 px-3 shadow-xl'>
      <div class='mt-24 flex flex-row items-center justify-between px-3 pb-3'>
        <span class='pl-2 text-sm font-bold uppercase'>Public Filters</span>
      </div>
      <div
        v-for='filter in filters.public_filters'
        :key='filter?.id'
        :class='[filter?.id === activeFilter?.id && "flex flex-row justify-between items-center bg-gray-800"]'
        class='my-3 cursor-pointer rounded-lg bg-gray-800 p-3 hover:bg-gray-900'
        @click='selectFilter(filter)'
      >
        <span>{{ filter.name }}</span>
      </div>

      <button
        v-if='false'
        class='mb-2 block rounded-lg bg-gray-600 py-2 px-4 text-base font-bold uppercase text-gray-800 outline-0 hover:bg-gray-500 active:bg-gray-500'
        @click='addFilter("public")'
      >
        <PlusIcon class='m-auto h-5 w-5'/>
      </button>

      <DividerComponent divider-color='border-gray-400'/>
      <div class='flex flex-row items-center justify-between py-6 px-3'>
        <span class='pl-2 text-sm font-bold uppercase'>Private Filters</span>
      </div>
      <div
        v-for='filter in filters.private_filters'
        :key='filter?.id'
        :class='[filter?.id === activeFilter?.id && "flex flex-row justify-between items-center p-3 my-3 bg-gray-800 rounded-lg"]'
        class='my-3 cursor-pointer rounded-lg bg-gray-800 p-3 hover:bg-gray-900'
        @click='selectFilter(filter)'
      >
        <span>{{ filter.name }}</span>
      </div>
      <button
        v-if='false'
        type='button'
        class='mb-2 block rounded-lg bg-gray-600 py-2 px-4 text-base font-bold uppercase text-gray-800 outline-0 hover:bg-gray-500 active:bg-gray-500'
        @click='addFilter("private")'
      >
        <PlusIcon class='m-auto h-5 w-5'/>
      </button>
    </div>
  </div>
</template>

<script lang='ts'>
  import { defineComponent, ref, watch } from 'vue'
  import { PlusIcon } from '@heroicons/vue/solid'
  import DividerComponent from '@/components/partials/DividerComponent.vue'
  import { useFilterStore } from '@/store/filter'
  import router from '@/router'
  import { ExtendedFilter } from '@/types/api/filter/filterTypes'

  const defaultSelectedFilter = () => {
    return {
      id: null,
      name: null,
      description: null,
      // eslint-disable-next-line camelcase
      records_per_page: 20,
      // eslint-disable-next-line camelcase
      created_at_label: '',
      author: '',
      public: false,
      default: false,
      fields: [],
      // eslint-disable-next-line camelcase
      filter_fields: [{
        id: null,
        field: {
          id: null,
          label: '',
          type: '',
        },
        module: {
          id: null,
          name: '',
        },
        conditions: [{
          id: null,
          // eslint-disable-next-line camelcase
          field_label: '',
          // eslint-disable-next-line camelcase
          field_module_label: '',
          operator: '',
          value: '',
        }],
      }],
    }
  }

  export default defineComponent({
    name: 'FilterMenuContent',
    components: {
      DividerComponent,
      PlusIcon,
    },
    props: {
      currentModule: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const filterStore = useFilterStore()
      const showFilters = ref(false)
      const modalType = ref('form')
      const selectedFilter = ref(defaultSelectedFilter())
      const activeFilter = ref()

      const filters = ref({
        public_filters: [],
        private_filters: [],
        active_filter: {
        },
      })

      watch(() => props.currentModule, () => {
        filters.value.public_filters = props.currentModule.public_filters
        filters.value.private_filters = props.currentModule.private_filters
      })

      watch(() => filterStore.activeFilter, (filter) => {
        activeFilter.value = filter
      })

      const addFilter = () => {
        filterStore.openFilterManagement = true
      }

      const selectFilter = (filter: ExtendedFilter) => {
        filterStore.setActiveFilter(filter)
        router.push('/' + props.currentModule.name)
      }

      function closeModal() {
        showFilters.value = false
      }

      return {
        addFilter,
        showFilters,
        activeFilter,
        filterStore,
        filters,
        selectFilter,
        modalType,
        selectedFilter,
        closeModal,
      }
    },
  })
</script>
