<template>
  <transition @mouseleave='onMouseLeave'>
    <div
      v-show='isOpen'
      class='absolute left-full top-0 z-50 min-h-screen overflow-y-auto md:block'
    >
      <div class='h-screen overflow-hidden'>
        <div class='flex h-full pl-0'>
          <FilterMenuContent :current-module='currentModule'/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'
  import FilterMenuContent from '@/layouts/partials/default/FilterMenuContent.vue'

  export default defineComponent({
    name: 'FilterMenu',
    components: {
      FilterMenuContent,
    },
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      currentModule: {
        type: Object,
        required: true,
      },
    },
    emits: ['mouse-left'],
    setup(props, { emit }) {
      const isOpen = ref(false)

      watch(() => props.open, (open) => {
        isOpen.value = open
      })

      const onMouseLeave = () => {
        emit('mouse-left')
      }

      return {
        isOpen,
        onMouseLeave,
      }
    },
  })
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  @apply transition-all duration-300;
}

.v-enter-from,
.v-leave-to {
  @apply opacity-0;
}
</style>
