<template>
  <component :is='layout'/>
</template>

<script lang='ts'>
  import { defineComponent, ref, watch } from 'vue'
  import DefaultLayout from './layouts/default.vue'
  import SimpleLayout from './layouts/simple.vue'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    name: 'App',
    components: {
      DefaultLayout,
      SimpleLayout,
    },
    setup() {
      const layout = ref<string>('DefaultLayout')
      const route = useRoute()

      watch(
        () => route.meta,
        async meta => {
          try {
            layout.value = meta?.layout as string ?? 'DefaultLayout'
          } catch (e) {
          }
        },
        {
          immediate: true,
        }
      )

      return {
        layout,
      }
    },
  })

</script>
