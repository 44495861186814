<template>
  <div class='flex flex-1 items-center'>
    <form
      v-if='!isSearching'
      class='w-full md:ml-0'
    >
      <label
        for='search-field'
        class='sr-only'
      >Search all files</label>
      <div class='relative flex flex-row items-center justify-center text-gray-400 focus-within:text-gray-600'>
        <div class='inline-flex w-full max-w-lg items-center bg-gray-50 px-3'>
          <SearchIcon
            class='h-4 w-4 shrink-0'
            aria-hidden='true'
          />
          <input
            id='search-field'
            v-model='searchValue'
            name='search-field'
            class='mx-auto h-full w-full border-transparent bg-transparent py-2 text-sm text-gray-900 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 focus:placeholder:text-gray-400'
            placeholder='Search'
            type='search'
          >
          <div
            class='w-auto shrink-0 cursor-pointer'
            @click='isSearching = true'
          >
            <p class='inline-flex w-full items-center gap-1 text-xs text-slate-400'>
              <abbr
                title='Command/Control'
                class='shrink-0 text-slate-300 no-underline dark:text-slate-500'
              >
                {{ modifierKeyPrefix() }}
              </abbr>
              K
            </p>
          </div>
        </div>
      </div>
    </form>

    <TransitionRoot
      :show='isSearching'
      as='template'
      appear
      @after-leave='searchValue = ""'
    >
      <Dialog
        as='div'
        class='relative z-10'
        @close='isSearching = false'
      >
        <TransitionChild
          as='template'
          enter='ease-out duration-300'
          enter-from='opacity-0'
          enter-to='opacity-100'
          leave='ease-in duration-200'
          leave-from='opacity-100'
          leave-to='opacity-0'
        >
          <div class='fixed inset-0 bg-gray-500/60 transition-opacity' />
        </TransitionChild>

        <div class='fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20'>
          <TransitionChild
            as='template'
            enter='ease-out duration-300'
            enter-from='opacity-0 scale-95'
            enter-to='opacity-100 scale-100'
            leave='ease-in duration-200'
            leave-from='opacity-100 scale-100'
            leave-to='opacity-0 scale-95'
          >
            <DialogPanel class='mx-auto max-w-xl overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all'>
              <Combobox @update:model-value='openRecord'>
                <div class='relative'>
                  <SearchIcon
                    class='pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                  <ComboboxInput
                    class='h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm'
                    placeholder='Search...'
                    :value='searchValue'
                    @change='searchValue = $event.target.value'
                  />
                </div>

                <div
                  v-if='searchValue === ""'
                  class='border-t border-gray-100 px-6 py-14 text-center text-sm sm:px-14'
                >
                  <SearchIcon
                    class='mx-auto h-6 w-6 text-gray-400'
                    aria-hidden='true'
                  />
                  <p class='mt-4 font-semibold text-gray-900'>Search for available records</p>
                  <p class='mt-2 text-gray-500'>Quickly access records by running a global search.</p>
                </div>

                <ComboboxOptions
                  v-if='results.length > 0 && searchValue !== ""'
                  static
                  class='max-h-80 scroll-pb-2 scroll-pt-11 space-y-2 overflow-y-auto pb-2'
                >
                  <li
                    v-for='(groups, key) in results'
                    :key='key'
                  >
                    <div
                      v-for='(groupItems, groupKey) in groups'
                      :key='groupKey'
                    >
                      <div class='inline-flex w-full items-center gap-1.5 bg-gray-100 px-4 py-2.5'>
                        <component
                          :is='IconComponents[groupItems.data[0].module_icon]'
                          :class='[groupItems.data[0]?.module_color, "h-5 w-5"]'
                        />
                        <h2 class='text-xs font-semibold text-gray-900'>
                          {{ groupKey }}
                        </h2>
                      </div>

                      <ul class='mt-2 text-sm text-gray-800'>
                        <ComboboxOption
                          v-for='item in groupItems.data'
                          :key='item.id'
                          v-slot='{ active }'
                          :value='item'
                          as='template'
                        >
                          <li
                            class='flex'
                            :class='["cursor-pointer select-none px-4 py-2 text-sm", active && "bg-gray-600 text-white"]'
                          >
                            <div
                              class='inline-flex w-full items-center justify-between gap-1'
                            >
                              <div class='inline-flex items-center gap-1'>
                                <p>{{ item.label }} - {{ new Date(item.updated_at).toLocaleString() }}</p>
                              </div>
                              <svg
                                v-if='active'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                stroke-width='1.5'
                                stroke='currentColor'
                                class='h-4 w-4'
                              >
                                <path
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  d='M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3'
                                />
                              </svg>
                            </div>
                          </li>
                        </ComboboxOption>
                      </ul>

                      <div
                        v-if='groupItems.current_page !== groupItems.last_page'
                        class='cursor-pointer items-center pt-2 pb-4 text-center text-sm text-gray-500'
                        @click='loadMore(groupItems.data[0].module_name, groupItems.current_page + 1)'
                      >
                        <span>Load more ...</span>
                      </div>
                    </div>
                  </li>
                </ComboboxOptions>

                <div
                  v-if='loadingResults && searchValue !== ""'
                  class='flex items-center justify-center border-t border-gray-100 px-6 py-14 text-center text-sm sm:px-14'
                >
                  <svg
                    class='-ml-1 mr-3 h-7 w-7 animate-spin text-gray-600'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      class='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      stroke-width='4'
                    />
                    <path
                      class='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    />
                  </svg>
                </div>

                <div
                  v-if='searchValue !== "" && results.length === 0 && !loadingResults'
                  class='border-t border-gray-100 px-6 py-14 text-center text-sm sm:px-14'
                >
                  <XCircleIcon
                    class='mx-auto h-6 w-6 text-gray-400'
                    aria-hidden='true'
                  />
                  <p class='mt-4 font-semibold text-gray-900'>No results found</p>
                  <p class='mt-2 text-gray-500'>We couldn’t find anything with that term. Please try again.</p>
                </div>
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { SearchIcon, XCircleIcon } from '@heroicons/vue/solid'
  import { Combobox,
           ComboboxInput, ComboboxOption,
           ComboboxOptions,
           Dialog,
           DialogPanel,
           TransitionChild,
           TransitionRoot } from '@headlessui/vue'
  import { searchInModules } from '@/api/requests/globalsearch/search'
  import router from '@/router'
  import { IconComponents } from '@/compositions/useIconComponents'
  import { debounce } from 'lodash-es'
  import { useAppStore } from '@/store/app'
  import { onKeyStroke, useMagicKeys } from '@vueuse/core'

  const appStore = useAppStore()
  const searchValue = ref('')
  const isSearching = ref(false)
  const loadingResults = ref(true)
  const results = ref([])

  const openRecord = (record) => {
    router.push({
      name: 'module.detail',
      params: {
        module: record.module_name,
        id: record.id,
      },
    }).then(() => {
      isSearching.value = false
    }).finally(() => {
      searchValue.value = ''
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadMore = (moduleName: string, nextPage: number) => {

    appStore.apiSmallLoading = true
    searchInModules(searchValue.value, {
      module: moduleName,
      currentPage: nextPage,
    }).then(response => {
      results.value = response
    }).finally(() => {
      appStore.apiSmallLoading = false
    })
  }

  watch(searchValue, debounce(() => {
    if (searchValue.value.length > 2) {
      isSearching.value = true
      loadingResults.value = true
      results.value = []

      appStore.apiSmallLoading = true

      searchInModules(searchValue.value).then(response => {
        results.value = response
      }).finally(() => {
        appStore.apiSmallLoading = false
        loadingResults.value = false
      })
    } else {
      results.value = []
    }
  }, 456)
  )

  const modifierKeyPrefix = () => {
    if (window.navigator.platform.indexOf('Mac') > -1) {
      return '⌘ +'
    } else {
      return 'CTRL +'
    }
  }

  const { command, ctrl } = useMagicKeys()

  onKeyStroke(['k'], async (e) => {
    if (command.value || ctrl.value) {
      e.preventDefault()
      isSearching.value = !isSearching.value
    }
  })

</script>

<style scoped>
#search-field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
</style>
