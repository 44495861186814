export const generateModuleRoutes = [
    {
        path: '/:module',
        props: (route: { query: { list: string } }) => ({
            list: parseInt(route.query.list) || 1,
        }),
        name: 'module.index',
        component: () => import(`@/pages/IndexPage.vue`),
        meta: {
            layout: 'DefaultLayout',
            view: `index`,
        },
    },
    {
        path: `/:module/:id/:tab?`,
        name: `module.detail`,
        component: () => import('@/pages/DetailPage.vue'),
        meta: {
            layout: 'DefaultLayout',
            view: 'detail',
        },
    },
    {
        path: `/:module/create`,
        name: `module.create`,
        component: () => import('@/pages/CreatePage.vue'),
        meta: {
            layout: 'DefaultLayout',
            view: 'create',
        },
    },
]