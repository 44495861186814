import { defineStore } from 'pinia'
import { User } from '@/types/general/UserType'
// import { useAuthStore } from '@/store/authentication'

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            user: null as User|null,
            googleQr: '',
            googleSecret: '',
        }
    },
    getters: {
        getUser: state => state.user,
        getGoogleQr: state => state.googleQr,
        getGoogleSecret: state => state.googleSecret,
    },
    actions: {
        loadUser() {
            // const authStore = useAuthStore()
        },
    },
})
