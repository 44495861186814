<template>
  <!-- Mobile menu -->
  <TransitionRoot
    as='template'
    :show='opened'
  >
    <Dialog
      as='div'
      class='md:hidden'
      @close='$emit("close-menu")'
    >
      <div class='fixed inset-0 z-40 flex'>
        <TransitionChild
          as='template'
          enter='transition-opacity ease-linear duration-300'
          enter-from='opacity-0'
          enter-to='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leave-from='opacity-100'
          leave-to='opacity-0'
        >
          <DialogOverlay class='fixed inset-0 bg-gray-600/75' />
        </TransitionChild>
        <TransitionChild
          as='template'
          enter='transition ease-in-out duration-300 transform'
          enter-from='-translate-x-full'
          enter-to='translate-x-0'
          leave='transition ease-in-out duration-300 transform'
          leave-from='translate-x-0'
          leave-to='-translate-x-full'
        >
          <div class='relative flex w-full max-w-xs flex-1 flex-col bg-gray-700 py-1'>
            <TransitionChild
              as='template'
              enter='ease-in-out duration-300'
              enter-from='opacity-0'
              enter-to='opacity-100'
              leave='ease-in-out duration-300'
              leave-from='opacity-100'
              leave-to='opacity-0'
            >
              <div class='absolute top-1 right-0 -mr-14 p-1'>
                <button
                  type='button'
                  class='flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white'
                  @click='$emit("close-menu")'
                >
                  <XIcon
                    class='h-6 w-6 text-white'
                    aria-hidden='true'
                  />
                  <span class='sr-only'>Close sidebar</span>
                </button>
              </div>
            </TransitionChild>
            <div class='overflow-y-auto'>
              <div class='group relative my-1 flex w-full flex-col items-center justify-center rounded-md text-xs font-medium hover:bg-gray-500'>
                <a
                  href='/'
                  class='relative z-10 flex w-full cursor-pointer flex-col items-center justify-center rounded-md p-3'
                >
                  <HomeIcon class='h-6 w-6 text-gray-300 group-hover:text-white'/>
                  <span class='mt-2 text-center text-white'>Home</span>
                </a>
              </div>

              <div
                v-for='groups in sidebarNavigation'
                :key='groups'
              >
                <div
                  class='text-white'
                >
                  <div
                    v-for='group in groups'
                    :key='group'
                    :class='typeof group === &apos;object&apos; ? &apos;border-b border-gray-400 text-white py-0.5&apos; : &apos;&apos;'
                  >
                    <div v-if='typeof group === &apos;object&apos;'>
                      <RouterLink
                        v-for='item in group'
                        :key='item.name'
                        v-slot='{ route, navigate }'
                        :to='"/" + item.name'
                        custom
                      >
                        <div
                          class='group relative my-1 flex w-full flex-col items-center justify-center rounded-md text-xs font-medium hover:bg-gray-500'
                        >
                          <a
                            :class='[isActiveRoute(route) ? "bg-gray-800 text-white" : "text-gray-100 hover:text-white"]'
                            class='relative z-10 flex w-full cursor-pointer flex-col items-center justify-center rounded-md p-3'
                            :aria-current='isActiveRoute(route) ? "page" : undefined'
                            @click.exact='$emit("close-menu"); navigate()'
                          >
                            <component
                              :is='
                                item.icon'
                              :class='[isActiveRoute(route) ? "text-white" : "text-gray-300 group-hover:text-white", "h-6 w-6"]'
                              aria-hidden='true'
                            />
                            <span class='mt-2 text-center'>{{ item.label }}</span>
                          </a>
                        </div>
                      </RouterLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
        <div
          class='w-14 shrink-0'
          aria-hidden='true'
        >
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
  import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { defineComponent, toRefs } from 'vue'
  import { RouteLocation, useRoute } from 'vue-router'
  import { IconComponents } from '@/compositions/useIconComponents'
  import { XIcon } from '@heroicons/vue/outline'

  export default defineComponent({
    name: 'MobileMenu',
    components: {
      Dialog,
      DialogOverlay,
      TransitionChild,
      TransitionRoot,
      ...IconComponents,
      XIcon,
    },
    props: {
      sidebarNavigation: {
        type: Object,
        default: null,
      },
      mobileMenuOpen: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['close-menu'],
    setup(props) {
      const opened = toRefs(props).mobileMenuOpen
      const route = useRoute()

      const isActiveRoute = (menuRoute: RouteLocation) => {
        return route.meta.module === menuRoute.params.module
      }

      return {
        opened,
        isActiveRoute,
      }
    },
  })

</script>
